import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  GoBack,
} from "../../components"
import ACTIONS from "../../redux/actions/actionTypes"
import { getCurrentPath } from "../../redux/actions"

const Fail = ({ location, getCurrentPath, selectedPlan, apiCallStatus }) => {
  const intl = useIntl()
  const [isValidPlan, setIsValidPlan] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch({ type: ACTIONS.CLEAR_PURCHASE_RESULT })
      dispatch({ type: ACTIONS.DELETE_TRAVELLERS})
      dispatch({ type: ACTIONS.DELETE_TRAVEL_PLAN})
    }
  })

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  })

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title={intl.formatMessage({ id: "fail.title" })}
              fontColor="var(--clr-primary-300)"
              barColor="var(--clr-primary-400)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                  justify-items: center;
                `}
              >
                <p
                  css={css`
                    max-width: 700px;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: var(--letter-spacing-sm);
                    line-height: 1.75;
                  `}
                >
                  {intl.formatMessage({ id: "fail.string" })}
                </p>
                {apiCallStatus && apiCallStatus.errorReason && (
                  <p
                    css={css`
                      max-width: 700px;
                      margin-bottom: 2rem;
                      font-size: 1rem;
                      font-weight: bold;
                      letter-spacing: var(--letter-spacing-sm);
                      line-height: 1.75;

                      span {
                        color: var(--clr-primary-400);
                      }
                    `}
                  >
                    The possible reason of this failure might be:{" "}
                    <span>{apiCallStatus.errorReason}</span>
                  </p>
                )}
                {selectedPlan && (
                  <PlanDisplayCard
                    noChangeBtn
                    borderColor="var(--clr-primary-400)"
                    boxShadow="var(--solid-shadow-primary400)"
                  />
                )}
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 4rem;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0"
                  onClick={() => navigate("/")}
                >
                  {intl.formatMessage({ id: "btn.return" })}
                </PrimaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem1" })}
            text={intl.formatMessage({ id: "redirect.text1" })}
            actionText={intl.formatMessage({ id: "redirect.action1" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan, apiCallStatus }) => ({
  selectedPlan,
  apiCallStatus,
})

export default connect(mapStateToProps, { getCurrentPath })(Fail)
